import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import QRCodeSlider from '../../../Core/Components/QRCodeSlider/QRCodeSlider';
import { RFPROFILESETUP } from '../../../../constants/applicationConstants';
import {
  DataTableHoc,
  Loader,
  ErrorFallback,
  deleteFilter,
  applyFilter,
  saveFilters,
} from 'smart-react';
import { GridColumnsList } from '../Components/Filter/FilterElements';
import DataColumns from '../Components/DataColumns/DataColumns';
import {
  ROW_LAYOUTS,
  PAGE_SIZE,
} from '../../../../constants/applicationConstants';
import ConfigurationContentSlider from '../Components/ContentSlider/ConfigurationContentSlider';
const BaseProfileSetupDataTable = React.lazy(
  () => import('../Components/DataTable/DataTable')
);
const ProfileSetupDataTable = DataTableHoc(BaseProfileSetupDataTable);

/**
 * RFPROFILESETUP main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The RFPROFILESETUP component.
 */
const RFProfileSetup = ({ profile }) => {
  const [userData, setUserData] = React.useState([]);
  const dataColumnsArray = DataColumns({});
  // used to check API is ready to call
  const [isReady, setIsReady] = React.useState(false);
  const [singleProfile, setSingleProfile] = React.useState('');
  const [showQRModal, setShowQRModal] = React.useState(false);
  const [isReloadData, setIsReloadData] = React.useState(false);
  /**
   * handle Generate QRCode
   * @param profile
   */
  const handleGenerateQRCode = (profile) => {
    setShowQRModal(true);
    setSingleProfile(profile);
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className='container mx-auto k-px-4'>
        <React.Suspense fallback={<Loader />}>
          <ProfileSetupDataTable
            profile={profile}
            deleteFilter={deleteFilter}
            applyFilter={applyFilter}
            saveFilters={saveFilters}
            moduleName={RFPROFILESETUP}
            dataColumns={dataColumnsArray}
            gridColumnsList={GridColumnsList}
            dataTableName={RFPROFILESETUP}
            setIsReady={setIsReady}
            isReady={isReady}
            rowLayoutConfigName={`${RFPROFILESETUP}.DataGrid.RowLayout`}
            pageLengthConfigName={`${RFPROFILESETUP}.DataGrid.ItemsPerPage`}
            dataSet={userData}
            setDataSet={setUserData}
            initialSort={{
              field: 'id',
              dir: 'desc',
            }}
            isCardonMobileView={false}
            total={process.env.DefaultLimit}
            defaultRowLayout={process.env.RowLayout}
            rowLayouts={ROW_LAYOUTS}
            pageSizes={PAGE_SIZE}
            isReloadData={isReloadData}
            handleGenerateQRCode={handleGenerateQRCode}
          />
        </React.Suspense>
        <ConfigurationContentSlider show={showQRModal}>
          <QRCodeSlider
            showQRModal={showQRModal}
            singleProfile={singleProfile}
            setSingleProfile={setSingleProfile}
            setShowQRModal={setShowQRModal}
            isReloadData={isReloadData}
            setIsReloadData={setIsReloadData}
          />
        </ConfigurationContentSlider>
      </div>
    </ErrorBoundary>
  );
};
export default RFProfileSetup;
