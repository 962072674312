import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { RFPROFILEGROUP } from '../../../../constants/applicationConstants';
import {
  DataTableHoc,
  Loader,
  ErrorFallback,
  deleteFilter,
  applyFilter,
  saveFilters,
} from 'smart-react';
import { GridColumnsList } from '../Components/Filter/FilterElements';
import DataColumns from '../Components/DataColumns/DataColumns';
import {
  ROW_LAYOUTS,
  PAGE_SIZE,
} from '../../../../constants/applicationConstants';
const BaseProfileDataTable = React.lazy(
  () => import('../Components/DataTable/DataTable')
);
const RFProfileGroupDataTable = DataTableHoc(BaseProfileDataTable);

/**
 * RFProfileGroup main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The RFProfileGroup component.
 */
const RFProfileGroup = () => {
  const [userData, setUserData] = React.useState([]);
  const dataColumnsArray = DataColumns({});
  // used to check API is ready to call
  const [isReady, setIsReady] = React.useState(false);
  
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className='container mx-auto'>
        <React.Suspense fallback={<Loader />}>
          <RFProfileGroupDataTable
            deleteFilter={deleteFilter}
            applyFilter={applyFilter}
            saveFilters={saveFilters}
            moduleName={RFPROFILEGROUP}
            dataColumns={dataColumnsArray}
            gridColumnsList={GridColumnsList}
            dataTableName={RFPROFILEGROUP}
            setIsReady={setIsReady}
            isReady={isReady}
            rowLayoutConfigName={`${RFPROFILEGROUP}.DataGrid.RowLayout`}
            pageLengthConfigName={`${RFPROFILEGROUP}.DataGrid.ItemsPerPage`}
            dataSet={userData}
            setDataSet={setUserData}
            initialSort={{
              field: 'id',
              dir: 'desc',
            }}
            isCardonMobileView={false}
            total={process.env.DefaultLimit}
            defaultRowLayout={process.env.RowLayout}
            rowLayouts={ROW_LAYOUTS}
            pageSizes={PAGE_SIZE}
            isDefaultSlider={true}
            contentSliderTitle={'Profile Group'}
          />
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default RFProfileGroup;
