import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@progress/kendo-react-buttons';
/**
 * data Columns for grid and mobile view
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Data Columns Array
 */
export const DataColumns = ({
  editContentSlider,
  handleGenerateQRCode,
  MultiViewCalenderFilterCell,
  dateRangeFilter,
  GridDateCell,
  setDateRangeFilter,
}) => {
  const dataColumns = [
    {
      field: 'SRFProfilesSetupId',
      title: 'Setup Id',
      editable: false,
      show: false,
      width: '250px',
      minResizableWidth: 50,
      filterable: false,
      excludeFromFilter: true,
    },
    {
      field: 'action',
      title: 'Action',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
      filterable: false,
      cell: (props) => (
        <td>
          <Button
            className={'qr-button k-m-2'}
            themeColor={'primary'}
            size={'small'}
            onClick={() => handleGenerateQRCode(props.dataItem)}
          >
            <FontAwesomeIcon icon={faQrcode} size='lg' title='Generate QR' />
          </Button>
        </td>
      ),
    },
    {
      field: 'SRFProfilesSetupName',
      title: 'Setup Name',
      editable: false,
      show: true,
      width: '250px',
      minResizableWidth: 50,
      filterable: false,
      cell: (props) => (
        <td
          className='edit-link'
          onClick={() => {
            editContentSlider(props.dataItem);
          }}
        >
          {props.dataItem.SRFProfilesSetupName}
        </td>
      ),
    },
    {
      field: 'SRFProfilesDetails',
      title: 'Description',
      editable: false,
      show: true,
      width: '250px',
      minResizableWidth: 50,
    },
    {
      field: 'WHID',
      title: 'Warehouse Id',
      editable: false,
      show: true,
      width: '250px',
      width: '250px',
      minResizableWidth: 50,
    },
    {
      field: 'RFVenName',
      title: 'RF Ven Name',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'MTFURL',
      title: 'MTF URL',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'MTFPort',
      title: 'MTF Port',
      editable: false,
      show: true,
      width: '250px',
      minResizableWidth: 50,
    },
    {
      field: 'MTFProtocol',
      title: 'MTF Protocol',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'auto_devcod_seqnum',
      title: 'Auto Device Code Sequence',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'created_by',
      title: 'Created By',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'created_date',
      title: 'Created Date',
      editable: false,
      show: true,
      width: '300px',
      minResizableWidth: 50,
      filter: 'date',
      type: 'dateRange',
      filterCell: (props) =>
        MultiViewCalenderFilterCell({
          ...props,
          dateRangefilter: dateRangeFilter,
          setDateRangeFilter,
          field: 'created_date',
        }),
      isRequired: true,
      cell: GridDateCell,
    },
    {
      field: 'modified_by',
      title: 'Modified By',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'modified_date',
      title: 'Modified Date',
      editable: false,
      show: true,
      width: '300px',
      minResizableWidth: 50,
      filter: 'date',
      type: 'dateRange',
      filterCell: (props) =>
        MultiViewCalenderFilterCell({
          ...props,
          dateRangefilter: dateRangeFilter,
          setDateRangeFilter,
          field: 'modified_date',
        }),
      isRequired: true,
      cell: GridDateCell,
    },
  ];

  return dataColumns;
};
export default DataColumns;
